import type { RouterConfig } from '@nuxt/schema'
import { APP_HEADER_HEIGHT } from '~/constants'

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const scrollAfterFinish = (position: any) => {
      // https://github.com/nuxt/framework/pull/3851#issuecomment-1157439905
      const { hooks } = useNuxtApp()

      return new Promise((resolve) => {
        hooks.hookOnce('page:finish', () => {
          nextTick(() => resolve(position))
        })
      })
    }

    if (savedPosition) {
      if (to.name === from.name) {
        return savedPosition
      }

      return scrollAfterFinish(savedPosition)
    }

    if (to.hash && !_useHashNavigation().isIntersectionNavigation) {
      const newPosition = {
        el: to.hash,
        top: $featureFlag('feature_show_new_landing')
          ? Number(getOnlyNumber(useCssVar('--header-height').value ?? '0'))
          : APP_HEADER_HEIGHT,
        behavior: 'smooth'
      } as any

      if (to.name === from.name) {
        return newPosition
      }

      return scrollAfterFinish(newPosition)
    }

    if (to.name !== from.name) {
      return scrollAfterFinish({
        top: 0
      })
    }
  }
}
